import { InMemoryCache, ApolloClient } from '@apollo/client'

const serverUrl = '/api/service/depot-bff/v1/graphql'
const secondaryBaseUrl = `https://developer.sandbox.eng.toasttab.com`
const secondaryUrl = secondaryBaseUrl.concat(serverUrl)

export type ActiveEnvironment = 'main' | 'secondary'

export const getApolloClient = (
  authToken?: string,
  activeEnv?: ActiveEnvironment
) => {
  const isSecondaryEnv = activeEnv !== undefined && activeEnv === 'secondary'
  const headersSecondary = {
    authorization: 'Bearer ' + authToken
  }
  return new ApolloClient({
    cache: new InMemoryCache(),
    uri: isSecondaryEnv ? secondaryUrl : serverUrl,
    headers: isSecondaryEnv ? headersSecondary : {},
    credentials: 'include'
  })
}
