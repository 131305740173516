import {
  Partner,
  UserEnvironment
} from '../../../packages/depot-banquet-props/src'
import { api } from '../../api'
import { getApolloClient } from '../../api/ApolloClient'
import { SecondaryEnvEntity } from '../../api/manageUserInSecondaryEnv/createSecondaryEnvUser'

export async function populateUserEnvironments(
  authToken: string,
  userGuid: string,
  partner: Partner
): Promise<UserEnvironment[]> {
  let userEnvironments = [] as UserEnvironment[]

  const isSwitcherFlagEnabled =
    partner.featureFlags?.find(({ name }) => name === 'partners-env-switcher')
      ?.enabled ?? false

  if (isSwitcherFlagEnabled && partner.crossEnvGuid !== null) {
    const userHasAccess = await hasUserAccessToSecondaryEnvForPartner(
      authToken,
      userGuid,
      partner
    )
    if (userHasAccess) {
      userEnvironments.push('secondary')
    }
  }
  userEnvironments.push('main')

  return userEnvironments
}

async function hasUserAccessToSecondaryEnvForPartner(
  authToken: string,
  userGuid: string,
  partner: Partner
): Promise<boolean> {
  return await api
    .getSecondaryEnvPartnerForUser(getApolloClient(authToken, 'secondary'))
    .then(async (associatedPartner) => {
      if (associatedPartner.guid === partner.crossEnvGuid) {
        return true
      } else {
        // Multiple partners associations not supported at this time:
        // user cannot be created in secondary env for crossEnvGuid partner too.
        return false
      }
    })
    .catch((e) => {
      console.error(e)
      // No associations found for the user in secondary env for the crossEnvGuid partner, which means no user yet (or user deleted)
      // So, we want to create a (new) user using the user info from main env.
      return createSecondaryEnvUserWithMainEnvInfo(
        authToken,
        userGuid,
        partner.guid,
        partner.crossEnvGuid!!
      )
    })
}

async function createSecondaryEnvUserWithMainEnvInfo(
  authToken: string,
  userGuid: string,
  partnerGuid: string,
  crossEnvGuid: string
): Promise<boolean> {
  const mainUserInfo = await api
    .getMainEnvUserInfo(
      partnerGuid,
      userGuid,
      getApolloClient(authToken, 'main')
    )
    .then((userInfo) => {
      return userInfo
    })
    .catch((e) => {
      console.error(e)
      return null
    })

  if (!mainUserInfo) {
    return false
  }

  const secondaryUserPayload: SecondaryEnvEntity = {
    guid: mainUserInfo.guid,
    username: mainUserInfo.username,
    firstName: mainUserInfo.firstName,
    lastName: mainUserInfo.lastName,
    partnerGuid: crossEnvGuid
  }
  return await api
    .createSecondaryEnvUser(
      getApolloClient(authToken, 'secondary'),
      secondaryUserPayload
    )
    .then(() => {
      return true
    })
    .catch((e) => {
      console.error(e)
      return false
    })
}
